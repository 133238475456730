<template>
  <div class="home">
    <Hero :img="img" :title="title" />
    <Content :content="ingressi" :ingressi="true" />
    <Gallery :images="firstImages" :columns="3" />
    <Separator color="orange" />
    <MediaText :header="talviHeader" :img="talviImg" :text="talviText" :mediaFirst="true" />
    <Gallery :images="secondImages" :columns="3" />
    <Separator color="orange" />
    <Gallery :images="thirdImages" :columns="3" />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
import Content from "@/components/Content.vue";
import MediaText from "@/components/MediaText.vue";
import Separator from "@/components/Separator.vue";
import Gallery from "@/components/Gallery.vue";

export default {
  name: "tekemista",
  components: {
    Hero,
    Content,
    MediaText,
    Separator,
    Gallery
  },
  data() {
    return {
      title: "Tekemistä",
      img: {
        name: "ohjelma.jpg",
        alt: ""
      },
      tilaImg: {
        name: "sauna-ja-laavu.jpg",
        alt: "Sauna ja laavu joen rannassa"
      },

      talviImg: {
        name: "tulilla.jpeg",
        alt: "Tunnelma nuotiolla"
      },
      metsastysImg: {
        name: "mehtalla.jpeg",
        alt: "Saalista mehtällä"
      },
      ingressi:
        "Luonnossa liikkumiseen, marjastukseen, kalastukseen, metsästykseen tai vaikka vaan oleiluun tila tarjoaa mukavat puitteet. Tilalta löytyy merkittyjä retkeilyreittejä tulistelupaikkoineen, veneitä vesillä liikkumiseen ja opaspalveluita mihin tahansa luontoaktiviteettiin. Lähiseudun parhaat marjapaikat, kalavedet ja riistamaat asiantuntevan oppaan kanssa antavat varmasti enemmän elämyksiä kuin moni osaa odottaakaan.",

      tilaText:
        "Tilan kotieläimiä pääsee isäntäväen mukana katsomaan päivittäisten askareiden lomassa ja rantasaunoja saa lämmittää vaikka keskellä päivää niin halutessaan. Tilan töihin saa aina osallistua, kaikki loppuu aikanaan, halonteko ei milloinkaan…",

      talviHeader: "Talvella",

      talviText:
        "Talvikautena Marjasuvannolla on hiihtolatu käytössä perinteisen tyylin murtsikointiin, helpohkot maastot umpihankihiihtoretkille ja pilkkipaikat ihan vieressä. Mutkamäkeen pääsee myös helposti päiväreissulle Pyhätunturille, joka meikäläisittäin on ihan vieressä, vain kolmen vartin ajon päässä. Myös Pelkosenniemen ja Savukosken välinen moottorikelkkareitti menee ihan Lomamajojen läheltä. Talvi-iltana rantasaunan lämmöstä avantoon pulahtaminen on jo elämys sinänsä…",

      metsastysHeader: "Metsästys",
      metsastysText: "Tähän kohtaa vois kerto metsästysmahdollisuuksista",

      firstImages: [
        {
          src: "saalis.jpg",
          alt: "Saalis valmistettuna heti rannassa"
        },
        {
          src: "iltanuotiolla.jpg",
          alt: "kesäilta nuotiolla"
        },
        {
          src: "joki_ja_vene.jpeg",
          alt: "Vene joella tulvan jälkeen"
        },
        {
          src: "kalalla.jpg",
          alt: "Kalavedet lähellä"
        },
        {
          src: "savukalaa.jpg",
          alt: "Kasta savustettua kalaa"
        },
        {
          src: "savustin.jpg",
          alt: "savustin löytyy pihasta"
        }
      ],
      secondImages: [
        {
          src: "otso.jpg",
          alt: "Karhu metsässä"
        },
        {
          src: "haukkala_talvella.jpeg",
          alt: "Talven kirkkaus Haukkalan edustalla"
        },
        {
          src: "kelkkailua.jpeg",
          alt: "Kelkkailu kirvottaa kunnon naurut"
        },
        {
          src: "pilkilla.jpeg",
          alt: "Pilkillä viihtyy perheen pienimmätkin"
        },
        {
          src: "hiihto.jpeg",
          alt: "Tietäkäyden tien oot vanko, vapaa on vain umpihanki"
        },
        {
          src: "talvipiha.jpeg",
          alt: "Talvinen piha"
        },
        {
          src: "sauna_2.jpeg",
          alt: "Sauna joelta"
        },
        {
          src: "avanto.jpeg",
          alt: "Avanto"
        },
        {
          src: "kelkkailua_2.jpeg",
          alt: "Kelkkailua omassa rauhassa"
        }
      ],
      thirdImages: [
        {
          src: "mehtalla_2.jpeg",
          alt: "Onnistuminen mehtalla"
        },
        {
          src: "mikko.jpeg",
          alt: "Tilan isäntä Mikko mukana jahdissa"
        },
        {
          src: "torni.jpeg",
          alt: "Hirvitorni on viimesenpälle"
        }
      ]
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
@import '../styles/variables.styl';
</style>

<style lang="stylus">
.hero {
  img {
    object-position: 50% 80%;
  }
}
</style>
